.userDashList {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 20px 30px;
}

.ant-layout-content {
	flex: auto;
}
