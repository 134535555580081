/*===== COLORS =====*/
$alpaca-green: #93D800;
$alpaca-blue : #001529;
$pale-yellow : #FBFFDF;
$red         : #FF0000;
$white       : #ffffff;
$medium-grey : #728096;
$dark-blue   : #021BFF;
$dark-grey   : #323A45;
$black       : #000000;

/*===== FONT =====*/
$family: "Pacifico";
$lg-heading-font-size: 36px;
$sm-text-size        : 14px;
$md-text-size        : 25px;

/*===== SIZES =====*/
$card-width: 160px;
$card-spacing: 10px;
$card-row-spacing: 10px;


$color-3: #222; // box font color
