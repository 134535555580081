.nav-header {
	height: 100vh;
	width: 100%;
	.userDashHeaderFont {
		color: $alpaca-green;
		font-family: Pacifico;
		font-style: normal;
		font-weight: normal;
		font-size: 36px;
		line-height: 63px;
		display: flex;
		align-items: center;
		&:hover {
			color: $alpaca-green;
		}
	}
	.ant-layout-sider-zero-width-trigger {
		top: 20px;
	}
	.userDashHeader {
		height: 83px;
		padding: 0;
		.userDashHeaderFont {
			margin-bottom: 0;
		}
		.userDashContentHeader {
			padding: 0 50px;
			background-color: $white;
			span {
				.ant-avatar {
					cursor: pointer;
					border: 1px solid $alpaca-green;
				}
				p {
					line-height: 0;
					font-size: 13px;
				}
			}
		}
	}
}

.ant-popover-inner-content {
	button {
		border: none !important;
		cursor: pointer;
	}
}