.Dropzone {
  height: 150px;
	width: 216px;
	position: absolute;
	top: 16px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
	flex-direction: column;
  font-size: 65px;
  color: transparent;
}

.FileInput {
  display: none;
}

.Highlight {
	background-color: rgba(255, 255, 255, 0.8);
	color: white;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
	overflow: hidden;
	.Content {
		display: flex;
		flex-direction: row;
		padding-top: 16px;
		box-sizing: border-box;
		width: 100%;
	}
}

.Files {
  margin-left: 15px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-left: 10px;
  font-size: 16px;
  color: #555;
}

.Row {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
  img {
    max-width: 10%;
  }
}

.ProgressBar {
  width: 100%;
  height: 8px;
	border-radius: 5px;
	background-color: #93d80080
}

.Progress {
	background-color: $alpaca-green;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
	margin-left: 20px;
}

.dropzone-component {
	display: flex;
	align-items: center;
	flex-direction: column;
	button {
		cursor: pointer;
		border: none;
    span {
			font-size: 14px;
			color: $dark-blue;
			&:hover {
			text-decoration: underline;
			color: $dark-blue;
			}
		}
	}
}
.upload-modal {
	.ant-alert-error {
		margin-bottom: 15px;
	}
}