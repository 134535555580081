.actual-player {
	width: 720px;
	@media screen and (max-width:820px) {
		width: 540px;
	}
	@media screen and (max-width:600px) {
		width: 320px;
	}
}

.carouselItems > .add-video-btn,
.video-card {
	min-width: $card-width;
	width: $card-width;
	height: 240px;
	margin: $card-spacing $card-row-spacing;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
}

.add-video-btn {
	i {
		font-size: 30px;
	}
	&:hover {
		background-color: #e4e4e4;
		color: inherit;
		border-color: rgb(217, 217, 217);
	}
	&:focus, &:active {
		color: inherit;
		border-color: rgb(217, 217, 217);
	}

}

.video-card {
	cursor: pointer;
	overflow: hidden;
	.ant-card-cover {
		height: 127px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: black;
	}
	.ant-card-body {
		overflow: hidden;
		padding: 10px;
		margin: 0 !important;
	}
	.ant-card-meta-detail {
		.ant-card-meta-title {
			font-size: 1rem;
			margin: 0;
		}
		.ant-card-meta-description {
			font-size: 0.75rem;
			:after {
				content: "";
				text-align: right;
				position: absolute;
				bottom: 0;
				right: 0;
				width: 100%;

				height: 3rem;
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7) 99%);
			}
		}
	}
}
