.prompt-item {
	margin-bottom: 1rem;
	
	.prompt-desc-header {
		margin-top: -10px;
	}
}

.ant-collapse-extra {
	margin-right: 30px;
}

