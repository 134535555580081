.auth-page {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('../imgs/footer.svg');
	background-repeat: no-repeat;
	background-position: bottom;
	.register-container, .login-container {
		width: 460px;
		border: 1px solid #eaeced;
		border-radius: 5px;
		padding: 42px 42px 36px;
		background-color: white;
		@media(max-width: 600px) {
			border: none;
			background-color: transparent;
		}
		.ant-alert {
			margin-bottom: 10px;
		}
		.auth-header {
				a {
				display: flex;
				align-items: center;
				padding-bottom: 20px;
				justify-content: center;
				h1 {
					font-family: Pacifico;
					color: $dark-grey;
					margin: 0 0 0 15px;
				}
				img {
					height: 70px;
				}
			}
		}
		.ant-form {
			input {
				height: 44px;
				width: 100%;
				border: 1px solid #9da3a6;
				color: #000;
				font-size: 15px;
			}
			.register-form-button, .login-form-button {
				@include button($alpaca-green);
					width: 100%;
					margin-top: 20px;
					min-height: 44px;
					color: white;
					font-weight: 700;
					font-size: 15px;
				}
			
		}
		.ant-divider {
			color: #6c7378;
		}
		.signup-link, .login-link {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $alpaca-green;
			background-color: white;
			width: 100%;
			margin-top: 20px;
			min-height: 44px;
			color: $alpaca-green;
			font-weight: 700;
			font-size: 15px;
		}
	}
}
