@mixin button($color) {
	background-color: $color;
	border-color    : $color;
	color: $dark-grey;
}

@mixin outlined-button($color) {
	border-color: $color;
}

@mixin texts() {
	font-family: "Merriweather", sans-serif;
	color: $color-3;
	font-size: 1.6rem;
}

@mixin userDashHeaderFont() {
	color: $alpaca-green;
	font-family: Pacifico;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 63px;
	display: flex;
	align-items: center;
	&:hover {
		color: $alpaca-green;
	}
}